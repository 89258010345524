export async function storeErrorModal(value) {
  await localStorage.setItem("errorModal", value);
}

export async function storeErrorStatus(value) {
  await localStorage.setItem("errorStatus", value);
}

export async function storeAccessToken(value) {
  await localStorage.setItem("access_token", value);
}

export function getAccessToken() {
  return localStorage.getItem("access_token");
}

// export async function storeUser(value) {
//   await localStorage.setItem("user", JSON.stringify(value));
// }

// export function getUser() {
//   return localStorage.getItem("user");
// }

export async function getValue(key) {
  return JSON.parse(await localStorage.getItem(key));
}

export async function setValue(key, value) {
  await localStorage.setItem(key, JSON.stringify(value));
}
