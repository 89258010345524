import React, { useState } from "react";
import AppWrapper from "../layouts/AppWrapper";
import { TextInput, SearchInput } from "../components/Form";
import Mask from "../components/Mask";
import { Modal } from "../components/Modal";

const Test = () => {
  const [modal, setModal] = useState(false);
  return (
    <AppWrapper>
      <button onClick={() => setModal(true)} className="btn btn-primary">
        Open
      </button>

      <Modal isOpen={modal} width="500px" height="320px">
        <div className="text-center">変更項目を保存しますか？</div>
        <div className="text-center">変更項目を保存しますか？</div>
        <div onClick={() => setModal(false)} className="btn btn-primary">
          OK
        </div>
      </Modal>
      {/* <ErrorModal isOpen={modal} onAction={() => setModal(false)}>
        変更項目を保存しますか？
      </ErrorModal> */}

      {/* <InfoModal isOpen={modal} onAction={() => setModal(false)}>
        変更項目を保存しますか？{" "}
      </InfoModal> */}
      {/* <ConfirmModal
        isOpen={modal}
        onConfirm={() => custfun()}
        onCancel={() => setModal(false)}
      >
        変更項目を保存しますか？{" "}
      </ConfirmModal> */}

      {/* Button */}
      <div className="button-primary w-200-px h-60-px">
        <div className="left-wrapper"></div>
        <div className="inner text-white"> Hello </div>
        <div className="right-wrapper"></div>
      </div>
      {/*End Button */}

      {/* Text Box */}
      <div className=" mt-5 text-input w-400-px h-50-px">
        <div className="text-box">
          <input type="text" className="input" />
        </div>
      </div>
      {/* Text Box */}

      {/* Search Box */}
      <div className="search-input mt-5">
        <div className="search-box h-45-px w-600-px">
          <input
            className="input"
            placeholder="グループ名やグループ番号にて検索"
            type="text"
          ></input>
        </div>
      </div>

      <div className="mt-5">
        <TextInput
          placeholder="What is your name?"
          width="200px"
          height="50px"
          onChange={(e) => console.log(e.target.value)}
        />

        <SearchInput
          className="mt-2"
          placeholder="グループ名やグループ番号にて検索"
          width="600px"
          height="45px"
          onChange={(e) => console.log(e.target.value)}
        />
      </div>

      <div className="mt-5">
        <Mask width="400px" height="200px" id="test-mask" maskHeight="220px" />
      </div>
    </AppWrapper>
  );
};

export default Test;
