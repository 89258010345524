// eslint-disable-next-line import/no-anonymous-default-export
export default {
    dashboard: {
        group: 'グループ',
        unpublished: '未発行',
    },   
    seedSetting: {
        title: 'シード設定',
        labelconvert: 'ペルソナからアルカナへの変換時、乱数から以下の計算式に従いseed値を算出。',
        labelrandom: '乱数テーブル要素数',
        labelmagnification: '倍率',
        labelprobability: '確率',
        labelformat: 'Seed値＝乱数テーブルから抽選した倍率×トークンの総合値',
        labelexpectedmultiple: '期待倍率',
        labelformula: '（期待倍率=Σ[k=1, n]倍率×確率）',
        labeltotal: '合計確率は <span class="text-primary fs-25-px">100%</span>になる必要があります。',
        labeltotalprobability: `合計確率`,
        labellackprobability: `足りない確率`,
        
    }, 
    group: {
        title: 'グループリスト',
        placeholdersearch: 'グループ名やグループ番号にて検索',
        groupname: 'グループ名',
        groupnumber: 'グループ番号',
        numberofpersonas: 'ペルソナ数',
        createddate: '作成日',
        action: 'アクション',
    },
    type: {
        title: 'タイプリスト',
        placeholdersearch: 'タイプ名やタイプ番号にて検索',
        typename: 'タイプ名',
        typenumber: 'タイプ番号',
        groupname: 'グループ名',
        createddate: '作成日',
        numberofpersonas: 'ペルソナ数',
        action: 'アクション',
    },
    management: {
        title: 'ペルソナ管理',
        placeholdersearch: 'グループ名やグループ番号にて検索',
        typename: 'タイプ名',
        typenumber: 'タイプ番号',
        groupname: 'グループ名',
        createddate: '作成日',
        numberofpersonas: 'ペルソナ数',
        action: 'アクション',
    },
    button: {
        create: '作成',
    }, 
    menu: {
        group: 'グループ',
    },  
}
