import { React } from "react";

/** Default Button */
export const Button = (props) => {
  const { width, text, height, className, onClick, buttonClass, marginRight } =
    props;

  return (
    <div
      className={`${buttonClass ? buttonClass : "button-primary"} ${className}`}
      style={{ width: width, height: height, marginRight: marginRight }}
      onClick={onClick}
    >
      <div className="left-wrapper" />
      <div className="inner" />
      <div className="right-wrapper" />
      <div className="text">{text}</div>
    </div>
  );
};

/** Menu Button */
export const MenuButton = (props) => {
  const { width, height, text, className, onClick, isSelected } = props;
  var buttonClass = isSelected ? "selected-button-menu" : "button-menu";
  return (
    <div
      className={`${buttonClass} ${className}`}
      style={{ width: width, height: height }}
      onClick={onClick}
    >
      <div className="left-wrapper" />
      <div className="inner" />
      <div className="right-wrapper" />
      <div className="text">{text}</div>
    </div>
  );
};

export const LoginButton = (props) => {
  const { width, text, height, className, onClick } = props;

  return (
    <div
      className={`button-login ${className}`}
      style={{ width: width, height: height }}
      onClick={onClick}
    >
      <div className="left-wrapper" />
      <div className="inner" />
      <div className="right-wrapper" />
      <div className="text-login">{text}</div>
    </div>
  );
};

export default Button;
