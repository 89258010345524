import Routes from "./routes";
import { AppProvider } from "./context/appContext";
import { AuthProvider } from "./context/authContext";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { history } from "./services/history";
import { QueryClient, QueryClientProvider } from "react-query";
import { ModalProvider } from "./context/modalContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <HistoryRouter history={history}>
          <ModalProvider>
            <AuthProvider>
              <div className="App">
                <Routes />
              </div>
            </AuthProvider>
          </ModalProvider>
        </HistoryRouter>
      </AppProvider>
    </QueryClientProvider>
  );
}

export default App;
