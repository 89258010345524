import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppWrapper from "../layouts/AppWrapper";
import ContentWrapper from "../layouts/ContentWrapper";
import { Button } from "../components/Button";
import { SearchInput } from "../components/Form";
import { ConfirmModal, InfoModal } from "../components/Modal";
import Pagination from "../components/common/Pagination";
import { fetchData } from "../services/fetch";
import { useQuery } from "react-query";
import { DeleteIcon } from "../components/common/Icon";
import { storeErrorModal } from "../services/storage";
import { strings, translations } from "../services/localization";
import GridTableHeader from "../components/common/table/GridTableHeader";

/** Content Body */
const Group = () => {
  const translation = translations;
  const content = translations.group;
  const button = translations.button;
  const navigate = useNavigate();

  /** States */
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [deleteInfoModal, setDeleteInfoModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [groups, setGroups] = useState([]);
  const [paginatedata, setPaginatedata] = useState([]);
  const [filter, setFilter] = useState();
  const [deleteName, setDeleteName] = useState();
  // Migrate to react-query
  const { isLoading, refetch } = useQuery({
    // This is a unique query key identifier, you can add a variable here to
    // refetch every time that variable changes
    queryKey: ["get-persona-group"],
    // Query Function,  function to be invoked for fetching
    queryFn: () =>
      fetchData({
        url: `api/persona_group?${filter ? "filter=" + filter : ""}`,
      }),
    // Function to be excuted when the request is successful
    onSuccess: (response) => {
      paginatedata.total_count = response.data.total_count;
      paginatedata.page_count = response.data.page_count;
      if (response.data.current_page * 10 > response.data.total_count) {
        paginatedata.current_page_amount = response.data.total_count;
      } else {
        paginatedata.current_page_amount = response.data.current_page * 10;
      }
      paginatedata.current_page = response.data.current_page;
      setGroups(response.data.groups);
    },
    // Function to be invoked when the request has failed.
    onError: (error) => {
      storeErrorModal(error.message);
    },
  });

  /** Functions */
  const handleSettingClick = (id) => {
    navigate(`/groups/${id}`);
  };

  const handlepagenateChange = (paginateData) => {
    setPaginatedata(paginateData);
    fetchData({
      url: filter
        ? "api/persona_group?page=" +
          paginateData.current_page +
          "&filter=" +
          filter
        : "api/persona_group?page=" + paginateData.current_page,
      method: "get",
    })
      .then((response) => {
        paginatedata.total_count = response.data.total_count;
        paginatedata.page_count = response.data.page_count;
        if (response.data.current_page * 10 > response.data.total_count) {
          paginatedata.current_page_amount = response.data.total_count;
        } else {
          paginatedata.current_page_amount = response.data.current_page * 10;
        }
        paginatedata.current_page = response.data.current_page;
        setGroups(response.data.groups);
      })
      .catch((error) => {
        console.log(error);
        storeErrorModal(error.message); //remove error dialog
      });
  };
  const enterSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      refetch();
    }
  };

  const handleDelete = () => {
    fetchData({ url: "api/persona_group/" + deleteId, method: "delete" })
      .then(() => {
        setDeleteConfirmModal(false);
        setDeleteInfoModal(true);
        refetch();
      })
      .catch((error) => {
        console.log(error);
        storeErrorModal(error.message); //remove error dialog
      });
  };

  const tableHeaderItem = [
    {
      headerName: strings.formatString(content.groupname),
      width: "119px",
    },
    {
      headerName: strings.formatString(content.groupnumber),
      width: "170px",
      className: "pe-2",
    },
    {
      headerName: strings.formatString(content.numberofpersonas),
      width: "150px",
    },
    {
      headerName: strings.formatString(content.createddate),
      width: "185px",
      // className: "pe-4",
    },
    {
      headerName: strings.formatString(content.action),
      width: "115px",
    },
  ];

  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        title={<Title title={strings.formatString(content.title)} />}
        topSpace={
          <TopSpace
            onSearch={enterSearch}
            filter={filter}
            setFilter={setFilter}
            placeholdersearch={strings.formatString(content.placeholdersearch)}
            create={strings.formatString(button.create)}
          />
        }
        bottomSpace={
          <Pagination
            onChange={handlepagenateChange}
            paginate_data={paginatedata}
          />
        }
        smallMarginRight
      >
        {/* Table */}
        <div className="h-100" style={{ marginTop: "0.5%" }}>
          <div className="table-responsive hide-scroll gird-table">
            <table className="w-100">
              <GridTableHeader tableHeaderItem={tableHeaderItem} />
              <tbody className="text-white body-font overflow-auto hide-scroll h-80 text-center">
                <tr>
                  <td colSpan={6} style={{ height: 12 }} />
                </tr>
                {groups &&
                  groups.map((group, i) => (
                    <tr
                      className="grid-table-body "
                      key={i}
                      style={{ marginBottom: "0.5%" }}
                    >
                      <td className="col" style={{ width: 119 }}>{group.name}</td>
                      <td className="col" style={{ width: 170 }}>{group.group_no}</td>
                      <td className="col" style={{ width: 150 }}>
                        {/* {group.parameters.length} */}
                        {group.threshold}
                      </td>
                      <td className="col" style={{ width: 185 }}>{group.created_at.split("T")[0]}</td>
                      <td
                        className="col align-items-center"
                        style={{ gap: "15%",width: 115 }}
                      >
                        <div className=" d-flex " style={{ gap: "18px" }}>
                          <div
                            style={{ marginLeft: "26%" }}
                            onClick={() => handleSettingClick(group.id)}
                            className="edit-icon"
                          ></div>
                          <div
                            onClick={() => {
                              setDeleteConfirmModal(true);
                              setDeleteId(group.id);
                              setDeleteName(group.name);
                            }}
                            className=" delete-icon"
                          >
                            <DeleteIcon></DeleteIcon>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {/* Body */}

          {/*Delete Modals */}
          <ConfirmModal
            isOpen={deleteConfirmModal}
            onConfirm={() => handleDelete()}
            onCancel={() => setDeleteConfirmModal(false)}
          >
            {deleteName} グループを削除しますか？
          </ConfirmModal>
          <InfoModal
            isOpen={deleteInfoModal}
            onAction={() => setDeleteInfoModal(false)}
          >
            {deleteName} グループが正常に削除されました。
          </InfoModal>
          {/* End Delete Modals */}
        </div>
      </ContentWrapper>
    </AppWrapper>
  );
};

/** Title */
const Title = (props) => {
  const { title } = props;
  return (
    <div className="mt-1" style={{ marginLeft: "4%" }}>
      {title}
    </div>
  );
};
/** Top Space */
const TopSpace = (props) => {
  const navigate = useNavigate();
  const { setFilter, onSearch, placeholdersearch, create } = props;
  return (
    <div className="text-white px-2 pb-5-px d-flex justify-content-between h-100">
      {/* Search Box */}
      <SearchInput
        placeholder={placeholdersearch}
        required
        onKeyDown={onSearch}
        width={"560px"}
        onChange={(e) => setFilter(e.target.value)}
      />
      {/* Create Button */}
      <Button
        text={create}
        className="w-175-px"
        onClick={() => navigate("/groups/create")}
      />
    </div>
  );
};

export default Group;
