import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import JP from "../assets/icons/jp-flag.svg";
import EN from "../assets/icons/en-flag.png";
import UserIcon from "../assets/icons/user.svg";
import BackgroundImage from "../assets/images/background_green_effect.png";
import { MenuButton } from "../components/Button";
import { AppContext } from "../context/appContext";
import { AuthContext } from "../context/authContext";
import BackgroundMask from "../components/BackgroundMask";
import { ConfirmModal, ErrorModal } from "../components/Modal";
import { getAccessToken, getValue } from "../services/storage";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../slices/localization";
const AppWrapper = (props) => {
  const { children, bgMask } = props;

  /** react */
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  /** Context */
  const {
    menus,
    setMenus,
    errorModal,
    setErrorModal,
    unexpectedErrorModal,
    setUnexpectedErrorModal,
    updateLang,
  } = useContext(AppContext);
  const { user, logout } = useContext(AuthContext);

  /** States */
  const [logoutConfirmModal, setLogoutConfirmModal] = useState(false);
  /** ToolKit selectors */
  const [lang, setLang] = useState("EN");

  //const { lang  } = useSelector((state) => state.localization);
  /** Use Effects */
  //check selected routes and change color
  useEffect(() => {
    /** if access token is null, redirect to login page */
    if (!getAccessToken()) {
      navigate("/login");
    }
    // console.log("Location =>" + location.pathname.substring(1));

    let path_name = location.pathname.substring(1);
    setMenus(
      menus.map((menu, i) => {
        if (path_name === "" && i === 0) {
          return { ...menu, is_selected: true };
        }
        if (
          path_name.startsWith(menu.link.substring(1)) &&
          menu.link.substring(1) !== ""
        ) {
          // console.log("Path Name => " + menu.link.substring(1));
          return { ...menu, is_selected: true };
        } else {
          return { ...menu, is_selected: false };
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Functions */
  const handleMenuClick = (index, data) => {
    // setMenus(
    //   menus.map((menu, i) => {
    //     if (index === i) {
    //       return { ...menu, is_selected: true };
    //     } else {
    //       return { ...menu, is_selected: false };
    //     }
    //   })
    // );

    navigate(data.link);
  };

  const handleLogout = () => {
    setLogoutConfirmModal(false);
    logout();

    // alert("Successfully Logout");
  };

  useEffect(() => {
    loadLangTimeZone();
  }, [lang]);

  const loadLangTimeZone = async () => {
    const language = await getValue("language");
    if (language) {
      if (language == "Japanese") {
        setLang("JP");
      } else if (language == "English") {
        setLang("EN");
      }
    } else {
      setLang("JP");
    }
  };

  const statusChangeHandler = (value) => {
    setLang(value);
    if (value === "EN") {
      updateLang("English");
    } else if (value === "JP") {
      updateLang("Japanese");
    }
    window.location.reload();
  };

  return (
    <>
      <div
        className="position-relative h-100-vh"
        style={{
          overflowX: "hidden",
        }}
      >
        {/* Background Green Effect */}
        <div
          className="position-absolute h-100 w-100"
          style={{
            backgroundImage: `url(${BackgroundImage})`,
            backgroundRepeat: "no-repeat",
            zIndex: -1,
          }}
        />
        {bgMask && (
          <div
            className="position-absolute"
            style={{ right: "610px", marginTop: "-130px" }}
          >
            <div className="">
              {/* <Mask
                width="1500"
                height="700"
                maskHeight="720"
                id="mask-video"
                style={{ zIndex: -2, opacity: 0.4 }}
              /> */}
              <BackgroundMask
                width="1000"
                height="620"
                maskHeight="880"
                id="mask-video"
                style={{ zIndex: -2, opacity: 0.4 }}
              />
            </div>
          </div>
        )}

        <div className="px-4">
          {/* App Bar */}
          <div
            className="d-flex justify-content-between"
            style={{ padding: "22px 0 27px 0" }}
          >
            <div
              className="align-self-end text-primary fs-35-px font-ethno-normal"
              style={{ marginLeft: "0.9%" }}
            >
              PERSONA REALITY
            </div>
            <div
              className="d-flex align-self-center align-self-end text-white "
              style={{ marginRight: "1%", gap: "80px" }}
            >
              <div className="d-flex gap-2 ">
                <div>
                  <img src={UserIcon} alt="user icon" />
                </div>
                <div className="fs-20-px">
                  {user ? (
                    user.name
                  ) : (
                    <span>
                      USERNAME <span className="blink">...</span>
                    </span>
                  )}
                </div>
              </div>
              <div
                className="d-flex gap-2 align-items-center"
                onClick={() => statusChangeHandler(lang === "JP" ? "EN" : "JP")}
              >
                <div>
                  <img
                    src={lang === "JP" ? JP : EN}
                    alt="Flag"
                    height="14px"
                    width="20px"
                  />
                </div>
                <div className="fs-20-px font-ethno-light">{lang}</div>
              </div>
            </div>
          </div>
          {/* End App Bar */}

          <div className="d-flex gap-1">
            {/* Side Bar */}
            <div
              className="w-20 position-relative"
              style={{ height: "625px", marginTop: "-15px" }}
            >
              <div
                className="d-flex flex-column justify-content-around w-200-px"
                style={{ height: "83%", marginLeft: "5%" }}
              >
                {/* Menu List */}
                {menus.map((menu, index) => (
                  <MenuButton
                    onClick={() => handleMenuClick(index, menu)}
                    key={index}
                    text={menu.text}
                    isSelected={menu.is_selected}
                    className=""
                    width={"173px"}
                    height={"40px"}
                  />
                ))}
                {/* End Menu List */}
              </div>
              <div
                className="position-absolute bottom-0"
                style={{ marginLeft: "5%" }}
              >
                <MenuButton
                  onClick={() => setLogoutConfirmModal(true)}
                  text="ログアウト"
                  // className=" h-50-px"
                  width={"173px"}
                  height={"40px"}
                />
              </div>
            </div>
            {/* End Side Bar */}

            {/* Content */}
            <div className="px-6 w-90" style={{ marginRight: "2%" }}>
              {children}
            </div>
            {/* End Content */}
          </div>
        </div>

        <ErrorModal isOpen={errorModal} onAction={() => setErrorModal(false)}>
          {errorModal}
        </ErrorModal>

        <ErrorModal
          isOpen={unexpectedErrorModal}
          onAction={() => setUnexpectedErrorModal(false)}
        >
          エラーが発生しました。
          <br /> もう一度お試しください。
        </ErrorModal>

        <ConfirmModal
          isOpen={logoutConfirmModal}
          onConfirm={() => handleLogout()}
          onCancel={() => setLogoutConfirmModal(false)}
        >
          本当にログアウトしますか？
        </ConfirmModal>
      </div>
    </>
  );
};

export default AppWrapper;
