import React, { useRef, useState } from "react";
import AppWrapper from "../layouts/AppWrapper";
import ContentWrapper from "../layouts/ContentWrapper";
import { SearchInput, TextInput } from "../components/Form";
import { useNavigate } from "react-router-dom";
import PaginationBottomSpace from "../components/PaginationBottomSpace";
import { DayPicker } from "react-day-picker";
import { usePopper } from "react-popper";
import "react-day-picker/dist/style.css";
import "../assets/scss/Calender.css";
import moment from "moment";

/** Content Body */
const Group = () => {
  const navigate = useNavigate();
  const handleDetailClick = (id) => {
    navigate(`/update-histories/${id}`);
  };
  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        className="content-wide-title"
        // title={<Title />
        title={<div style={{ marginLeft: "4%" }}>アップデート歴</div>}
        topSpace={<TopSpace />}
        bottomSpace={<PaginationBottomSpace />}
      >
        <div className="row">
          <div className="table-responsive">
            <table className="w-100 h-100">
              <thead>
                <tr className="grid-table-header text-primary text-center font-notosans-jp header-font">
                  <th className="fw-normal" style={{ width: 150 }}>
                    グループ名
                  </th>
                  <th className="fw-normal" style={{ width: 280 }}>
                    タイプ名
                  </th>
                  <th className="fw-normal" style={{ width: 130 }}>
                    ペルソナ数
                  </th>
                  {/*<th className="fw-normal" style={{ width: 200 }}>*/}
                  {/*  ステータス*/}
                  {/*</th>*/}
                  <th className="fw-normal" style={{ width: 160 }}>
                    アプデート日
                  </th>
                  <th style={{ width: 80 }}></th>
                </tr>
              </thead>
              <tbody className="text-white body-font overflow-auto hide-scroll h-80 text-center">
                <tr>
                  {/* divider row */}
                  <td colSpan="6" style={{ height: 20 }}></td>
                </tr>
                {[...Array(10)].map((e, i) => (
                  <tr className="mb-3 grid-table-body " style={{ height: 42 }} key={i}>
                    <td className="col" >Groupname 12</td>
                    <td className="col">1234567890123456</td>
                    <td className="col">60</td>
                    {/*<td className="col">販売中</td>*/}
                    <td className="col">2023.03.15</td>
                    <td className="col d-flex align-items-center justify-content-center">
                      <div
                        onClick={() => handleDetailClick(i + 1)}
                        className="detail-more-icon mt-2"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </ContentWrapper>
    </AppWrapper>
  );
};

/** Title */
// const Title = () => {
//   return <div className="d-flex">{/* Title Content */}</div>;
// };

/** Top Space */
const TopSpace = () => {
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const popperRef = useRef(null);
  const inputRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const [selectedDate, setSelectedDate] = useState();

  const [isEndDatePopperOpen, setIsEndDatePopperOpen] = useState(false);
  const endDatePopperRef = useRef(null);
  const endDateInputRef = useRef(null);
  const [endDatePopperElement, setEndDatePopperElement] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState();

  const popper = usePopper(popperRef.current, popperElement, {
    placement: "bottom-start",
  });
  const endDatePopper = usePopper(
    endDatePopperRef.current,
    endDatePopperElement,
    {
      placement: "bottom-start",
    }
  );

  const closePopper = () => {
    setIsPopperOpen(false);
    inputRef?.current?.focus();
  };

  const closeEndDatePopper = () => {
    setIsEndDatePopperOpen(false);
    endDateInputRef?.current?.focus();
  };
  const handleInputClick = () => {
    setIsPopperOpen(!isPopperOpen);
  };
  const handleEndDateInputClick = () => {
    setIsEndDatePopperOpen(!isEndDatePopperOpen);
  };
  return (
    <div className="text-white px-2 pb-5-px d-flex justify-content-between h-100">
      {/* Search Box */}
      <SearchInput
        placeholder="グループ名やグループ番号にて検索"
        width="361.62px"
        required
      />
      {/* Date */}
      <div className="d-flex">
        <div ref={popperRef}>
          <TextInput
            className="w-140-px fs-18-px h-100"
            required
            value={moment(selectedDate).format("YYYY.MM.DD")}
            inputClass="text-center font-18px-impt font-notosans-jp pb-1"
            isDisabled={true}
            onClick={() => handleInputClick()}
          />
        </div>
        {isPopperOpen && (
          <div
            tabIndex={-1}
            style={{
              ...popper.styles.popper,
              backgroundColor: "black",
              zIndex: 10000,
            }}
            className="dialog-sheet"
            {...popper.attributes.popper}
            ref={setPopperElement}
            aria-label="DayPicker calendar"
          >
            <DayPicker
              initialFocus={isPopperOpen}
              mode="single"
              modifiersStyles={{}}
              // defaultMonth={selected}
              selected={selectedDate}
              onSelect={(value) => {
                setSelectedDate(value);
                closePopper();
              }}
            />
          </div>
        )}
        {/* Between Two Date Inputs */}
        <div className="h-100 w-20-px d-flex align-items-center justify-content-center mx-0">
          <div className="w-15-px h-1-px bg-primary"></div>
        </div>
        <div ref={endDatePopperRef}>
          <TextInput
            className="w-140-px fs-18-px h-100"
            required
            value={moment(selectedEndDate).format("YYYY.MM.DD")}
            inputClass="text-center font-18px-impt font-notosans-jp pb-1"
            onClick={() => handleEndDateInputClick()}
          />
        </div>
        {isEndDatePopperOpen && (
          <div
            tabIndex={-1}
            style={{
              ...endDatePopper.styles.popper,
              backgroundColor: "black",
              zIndex: 10000,
            }}
            className="dialog-sheet"
            {...endDatePopper.attributes.popper}
            ref={setEndDatePopperElement}
            aria-label="DayPicker calendar"
          >
            <DayPicker
              initialFocus={isEndDatePopperOpen}
              mode="single"
              modifiersStyles={{}}
              // defaultMonth={selected}
              selected={selectedEndDate}
              onSelect={(value) => {
                setSelectedEndDate(value);
                closeEndDatePopper();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Group;
