import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppWrapper from "../layouts/AppWrapper";
import ContentWrapper from "../layouts/ContentWrapper";
import Button from "../components/Button";
import { TextInput } from "../components/Form";
import { ConfirmModal, InfoModal } from "../components/Modal";
import { DeleteIcon } from "../components/common/Icon";

/** Content Body */
const Group = () => {
  /** States */
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [deleteInfoModal, setDeleteInfoModal] = useState(false);
  const [digit, setDigit] = useState(0);

  /** Functions */
  const handleDelete = (id) => {
    alert("action delete");
    setDeleteConfirmModal(false);
    setDeleteInfoModal(true);
  };
  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        setheighttitle={'true'}
        title={
          <div className="mt-2" style={{ marginLeft: "-1%" }}>
            内部値アップデート
          </div>
        }
        topSpace={<TopSpace />}
        className="content-wide-title"
        smallMarginRight
      >
        <div
          className="text-white overflow-auto hide-scroll"
          style={{ height: "125vh" }}
        >
          <div className="d-flex" style={{ marginTop: "2%" }}>
            <div className="w-50 d-flex">
              <div className="w-25 fs-20-px mt-1 me-3">タイプ名</div>
              <div className="w-70">
                <TextInput
                  className="h-40-px"
                  required
                  width="230.46px"
                  inactive
                  inputClass="ms-3"
                />
              </div>
            </div>
            <div className="d-flex" style={{ width: "65%" }}>
              <div className="fs-20-px mt-1" style={{ width: "23%" }}>
                タイプ番号
              </div>
              <div className="w-70">
                <TextInput
                  className="h-40-px"
                  required
                  width="230.46px"
                  inactive
                  inputClass="ms-3"
                />
              </div>
            </div>
          </div>
          <div className="fs-20-px" style={{ marginTop: "35px" }}>
            カスタムパラメーターリスト
          </div>
          <div className="mt-4 row gap-3">
            {[...Array(6)].map((e, i) => (
              <div className="col">
                <div className="fs-20-px font-europa-regular">Parameter1</div>
                <div className="mt-1 d-flex gap-1">
                  <div className="fs-20-px">1.00</div>
                  <div className="arrow-icon mt-10-px" />
                  <div className="w-60-px">
                    {i === 3 ? (
                      <TextInput
                        errorMessage="スコアは0-0000まで設定可能です。"
                        errorClass="error-without-right"
                        className="h-30-px w-60-px text-input-lesser-radius"
                        textboxClass="w-60-px"
                        required
                      />
                    ) : (
                      <TextInput
                        className="h-30-px text-input-lesser-radius"
                        required
                        textboxClass="w-60-px"
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div style={{ marginTop: "6%" }}>
            <div className="d-flex">
              <div className="fs-20-px pt-1">称号</div>
              <div className="pt-1" style={{ marginLeft: "6%" }}>
                <div className="random-plus"></div>
              </div>
            </div>
            <div
              className="d-flex"
              style={{ marginTop: "1%", height: "140px" }}
            >
              <div className="w-70">
                <div className="mt-2 d-flex flex-column gap-4 h-150-px overflow-auto hide-scroll">
                  {[...Array(2)].map((e, i) => (
                    <div className="d-flex" style={{ gap: "2%" }}>
                      <div style={{ width: "230.46px" }}>
                        <TextInput className="w-100 h-40-px" required />
                      </div>
                      <div
                        className="w-10 d-flex align-items-center justify-content-center"
                        style={{ paddingRight: "3%" }}
                      >
                        <div
                          onClick={() => setDeleteConfirmModal(true)}
                          className="delete-icon"
                        >
                          <DeleteIcon></DeleteIcon>
                        </div>
                      </div>
                      <div style={{ width: "230.46px" }}>
                        <TextInput className="w-100 h-40-px" required />
                      </div>
                      <div
                        className="w-10 d-flex align-items-center justify-content-center"
                        style={{ paddingRight: "3%" }}
                      >
                        <div
                          onClick={() => setDeleteConfirmModal(true)}
                          className="delete-icon"
                        >
                          <DeleteIcon></DeleteIcon>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="w-40"
                style={{ paddingRight: "65px", paddingTop: "10px" }}
              >
                <div
                  className="fs-20-px text-center"
                  style={{ marginBottom: "7%" }}
                >
                  総合値
                </div>
                <div className="mt-3 d-flex gap-4 justify-content-center">
                  <div
                    className="minus-icon mt-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => setDigit((digit) => digit - 1)}
                  ></div>
                  <div>
                    <TextInput
                      className="h-30-px text-input-lesser-radius"
                      onChange={(event) => setDigit(+event.currentTarget.value)}
                      value={digit}
                      inputClass="text-center fs-20-px pb-1"
                    />
                  </div>
                  <div
                    className="plus-icon"
                    style={{ cursor: "pointer", marginTop: "6px" }}
                    onClick={() => setDigit((digit) => digit + 1)}
                  ></div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "1.5%" }}>
              <div className="fs-20-px">ペルソナ</div>
              <div className="mt-3">
                <div
                  className="overflow-auto hide-scroll"
                  style={{ height: "410px" }}
                >
                  {[...Array(12)].map((e, i) => (
                    <div className="row" style={{ marginTop: "22px" }}>
                      {[...Array(3)].map((e, i) => (
                        <div className="col">
                          <div className="position-relative w-300-px ">
                            <TextInput className="h-40-px" required />
                            <div
                              className="copy-icon position-absolute top-50 translate-middle"
                              style={{ right: "10px" }}
                            ></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/*Delete Modals */}
          <ConfirmModal
            isOpen={deleteConfirmModal}
            onConfirm={() => handleDelete()}
            onCancel={() => setDeleteConfirmModal(false)}
          >
            〇〇称号を削除しますか？
          </ConfirmModal>
          <InfoModal
            isOpen={deleteInfoModal}
            onAction={() => setDeleteInfoModal(false)}
          >
            〇〇称号が正常に削除されました。
          </InfoModal>
          {/* End Delete Modals */}
        </div>
      </ContentWrapper>
    </AppWrapper>
  );
};

/** Title */
// const Title = () => {
//   return <div className="d-flex">{/* Title Content */}</div>;
// };

/** Top Space */
const TopSpace = () => {
  const navigate = useNavigate();

  /** States */
  const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
  const [updateInfoModal, setUpdateInfoModal] = useState(false);

  /** Functions */
  const handleUpdate = (id) => {
    alert("action update");
    setUpdateConfirmModal(false);
    setUpdateInfoModal(true);
  };

  const redirectUpdate = () => {
    setUpdateInfoModal(false);
    navigate("/update-histories");
  };
  return (
    <div className="text-white px-2 pb-5-px d-flex justify-content-end h-100">
      {/* Token Update Button */}
      <Button
        onClick={() => setUpdateConfirmModal(true)}
        text="アップデート"
        className="w-175-px"
      />

      {/*Update Modals */}
      <ConfirmModal
        isOpen={updateConfirmModal}
        onConfirm={() => handleUpdate()}
        onCancel={() => setUpdateConfirmModal(false)}
      >
        トークンの内部値をアップデートしますか？
      </ConfirmModal>
      <InfoModal isOpen={updateInfoModal} onAction={() => redirectUpdate()}>
        トークンの内部値は正常にアップデートされました。
      </InfoModal>
      {/* End Update Modals */}
    </div>
  );
};

export default Group;
