export default function PaginationBottomSpace() {
  return (
    <div
      className="text-white d-flex justify-content-between"
      style={{ margin: "0 9%" }}
    >
      <div className="left-arrow" />
      <div className="font-size font-ethno-light" style={{ marginTop: "10px" }}>
        10 / 20
      </div>
      <div className="right-arrow" />
    </div>
  );
}
