import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppWrapper from "../layouts/AppWrapper";
import ContentWrapper from "../layouts/ContentWrapper";
import { SearchInput } from "../components/Form";
import PaginationBottomSpace from "../components/PaginationBottomSpace";
import { PenIcon } from "../components/common/Icon";
import Pagination from "../components/common/Pagination";
import { fetchData } from "../services/fetch";
import { useQuery } from "react-query";
import { storeErrorModal } from "../services/storage";
import { strings, translations } from "../services/localization";

/** Content Body */
const Group = () => {
  
  const translation = translations;
  const content = translations.management;
  const button = translations.button;
  const navigate = useNavigate();
  const [paginatedata, setPaginatedata] = useState([]);
  const [filter, setFilter] = useState();
  const [managements, setManagements] = useState([]);
  const handleEditClick = (id) => {
    navigate(`/management/${id}`);
  };
  // Migrate to react-query
  const { isLoading, refetch } = useQuery({
    // This is a unique query key identifier, you can add a variable here to
    // refetch every time that variable changes
    queryKey: ["get-persona-group"],
    // Query Function,  function to be invoked for fetching
    queryFn: () => fetchData({ url: `api/management?${filter ? "filter=" + filter : ""}`, }),
    // Function to be excuted when the request is successful
    onSuccess: (response) => {
      paginatedata.total_count = response.data.total_count;
      paginatedata.page_count = response.data.page_count;
      if (response.data.current_page * 10 > response.data.total_count) {
        paginatedata.current_page_amount = response.data.total_count;
      } else {
        paginatedata.current_page_amount = response.data.current_page * 10;
      }
      paginatedata.current_page = response.data.current_page;
      setManagements(response.data.managementlist);
    },
    // Function to be invoked when the request has failed.
    onError: (error) => {
      storeErrorModal(error.message);
    },
  });
  const handlepagenateChange = (paginateData) => {
    setPaginatedata(paginateData);
    fetchData({
      url: filter
        ? "api/management?page=" +
          paginateData.current_page +
          "&filter=" +
          filter
        : "api/management?page=" + paginateData.current_page,
      method: "get",
    })
      .then((response) => {
        paginatedata.total_count = response.data.total_count;
        paginatedata.page_count = response.data.page_count;
        if (response.data.current_page * 10 > response.data.total_count) {
          paginatedata.current_page_amount = response.data.total_count;
        } else {
          paginatedata.current_page_amount = response.data.current_page * 10;
        }
        paginatedata.current_page = response.data.current_page;
        setManagements(response.data.managementlist);
      })
      .catch((error) => {
        console.log(error);
        storeErrorModal(error.message); //remove error dialog
      });
  };
  const enterSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      refetch();
    }
  };

  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        className="content-wide-title"
        title={<Title />}
        topSpace={<TopSpace onSearch={enterSearch} filter={filter} setFilter={setFilter} placeholdersearch={strings.formatString(content.placeholdersearch)}  />}
        bottomSpace={<Pagination
          onChange={handlepagenateChange}
          paginate_data={paginatedata}
        />}
        smallMarginRight
      >
        {/* Table */}
        <div className="row h-100">
          <div className="table-responsive hide-scroll  gird-table">
            <table className="w-100" style={{ marginTop: "0.5%" }}>
              <thead class="grid-table-header text-primary text-center font-notosans-jp header-font" style={{ height: 50 }}>
                <th class="fw-normal" style={{ width: 140 }}>
                  グループ名
                </th>
                <th class="fw-normal" style={{ width: 260 }}>
                  グループタイプ
                </th>
                <th
                  class="fw-normal"
                  style={{ width: 130, paddingRight: "3%" }}
                >
                  ペルソナ数
                </th>
                <th
                  class="fw-normal"
                  style={{ width: 200, paddingRight: "2%" }}
                >
                  作成日
                </th>
                <th
                  class="fw-normal"
                  style={{ width: 120, paddingRight: "1.5%" }}
                >
                  ステータス
                </th>
                <th style={{ width: 65 }}></th>
              </thead>
              {/*End Header */}
              {/* Body */}
              <tbody className="text-white body-font text-center font-notosans-jp overflow-auto hide-scroll h-90">
                <tr colSpan="6" >
                  <td></td>
                </tr>
                {/* Test Data Loop */}
                {managements.map((management, i) => (
                  <tr
                    class="mb-3 grid-table-body"
                    key={i}
                    style={{ marginBottom: "0.3%" }}
                  >
                    <td class="col" style={{ width: 140 }}>{management.group_name}</td>
                    <td class="col" style={{ width: 260 }}>{management.type_name}</td>
                    <td class="col" style={{ width: 130, paddingRight: "3%" }}>
                      {management.ordercount?management.ordercount:management.remaincount}
                    </td>
                    <td className="col" style={{ width: 200, paddingRight: "2%" }}>
                      {management.persona_date.split("T")[0]}
                    </td>
                    <td
                      class={`${
                        management.remaincount ? "text-secondary" : ""
                      }`}
                      style={{ width: 120, paddingRight: "1.5%" }}
                    >
                      販売中
                    </td>
                    <td style={{ width: 65 }}>
                      <div
                        onClick={() => handleEditClick(i + 1)}
                        className="pen-icon"
                        style={{}}
                      >
                        <PenIcon></PenIcon>
                      </div>
                    </td>
                  </tr>
                ))}
                {/*End Test Data Loop */}
              </tbody>
            </table>
          </div>
          {/* End Body */}
        </div>
      </ContentWrapper>
    </AppWrapper>
  );
};

/** Title */
const Title = () => (
  <div style={{ marginLeft: "5%", marginTop: "2%" }}>ペルソナ管理</div>
);

/** Top Space */
const TopSpace = (props) =>  {
  const { setFilter, onSearch ,placeholdersearch ,create} = props;
  return (
    <div className="text-white px-2 pb-5-px h-100">
      {/* Search Box */}
      <SearchInput
        placeholder={placeholdersearch}
        onKeyDown={onSearch}
        className="h-100"
        required
        width="410px"
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export default Group;
