import { configureStore } from "@reduxjs/toolkit";
import LocalizationReducer from "./slices/localization";
import PublishReducer from "./slices/publish";
import TypeReducer from "./slices/type";
import LoginReducer from "./slices/login";

export const store = configureStore({
  reducer: {
    localization: LocalizationReducer,
    publish: PublishReducer,
    type: TypeReducer,
    login: LoginReducer,
  },
});
