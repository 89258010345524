import { React } from "react";

const GridTableHeader = ({ tableHeaderItem }) => {
  return (
    <thead>
      <tr className="grid-table-header text-primary text-center font-notosans-jp header-font">
        {tableHeaderItem &&
          tableHeaderItem.map((HeaderItem, i) => (
            <th
              className={`fw-normal ${HeaderItem.className}`}
              style={{ width: `${HeaderItem.width}` }}
            >
              {HeaderItem.headerName}
            </th>
          ))}
      </tr>
    </thead>
  );
};
export default GridTableHeader;
