import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lang: "JP",
};

export const localizationSlice = createSlice({
  name: "localization",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.lang = action.payload;
    },
  },
});

export const { setLanguage } = localizationSlice.actions;
export default localizationSlice.reducer;
