import React, { useState, useContext } from "react";
import BackgroundDots from "../assets/images/transparent-bg-dots.png";
import LoginBox from "../layouts/LoginBox";
import { LoginInput } from "../components/Form";
import LoginMask from "../components/LoginMask";
import { AppContext } from "../context/appContext";
import { AuthContext } from "../context/authContext";
import UserIcon from "../assets/icons/user-solid.svg";
import LockIcon from "../assets/icons/lock.svg";
import { useDispatch, useSelector } from "react-redux";
import { setErrorCode } from "../slices/login";

const Login = () => {
  /** react */
  const dispatch = useDispatch();
  /** Context */
  const { login } = useContext(AuthContext);
  const { errorMsg, errorCode } = useSelector((state) => state.login);
  /** States */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const enterLogin = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      validate("email", email);
      validate("password", password);
      dispatch(setErrorCode(""));
      if (email && password) {
        login({ email, password });
      }
    }
  };
  const UserLogin = (e, email, password) => {
    e.preventDefault();
    validate("email", email);
    validate("password", password);
    dispatch(setErrorCode(""));
    if (email && password) {
      login({ email, password });
    }
  };

  const validate = (type, value) => {    
    dispatch(setErrorCode(""));
    if (type === "email") {
      setEmail(value);
      if (value === "") {
        setEmailValid(false);
      } else {
        setEmailValid(true);
      }
    } else if (type === "password") {
      setPassword(value);
      if (value === "") {
        setPasswordValid(false);
      } else {
        setPasswordValid(true);
      }
    }
  };

  return (
    <>
      <div
        className="bg-dark d-flex align-items-center"
        style={{
          backgroundImage: `url(${BackgroundDots})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <LoginBox>
          <div className="row h-100">
            <div className="col d-flex">
              <div style={{ marginLeft: "85px", marginTop: "107px" }}>
                <div class="login-container">
                  <form>
                    <div
                      onKeyDown={enterLogin}
                      className="d-flex justify-content-center"
                    >
                      <div className="position-relative">
                        <LoginInput
                          type="email"
                          placeholder=""
                          width="378px"
                          height="55px"
                          onChange={(e) => validate("email", e.target.value)}
                          onBlur={(e) => validate("email", e.target.value)}
                        />
                        <div
                          className="position-absolute user-icon translate-middle"
                          style={{ left: "30px", top: "45%" }}
                        >
                          <img src={UserIcon} alt="user-icon" />
                        </div>
                      </div>
                    </div>
                  </form>
                  {!emailValid ? (
                    <div className="error-valid-login mt-2">
                      ユーザー名を入力してください
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div class="login-container">
                  <form>
                    <div
                      onKeyDown={enterLogin}
                      className="d-flex justify-content-center"
                      // style={{
                      //   marginTop: "41.34px",
                      // }}
                    >
                      <div className="position-relative">
                        <LoginInput
                          type="password"
                          placeholder=""
                          width="378px"
                          height="55px"
                          onChange={(e) => validate("password", e.target.value)}
                          onBlur={(e) => validate("password", e.target.value)}
                        />
                        <div
                          className="position-absolute user-icon translate-middle"
                          style={{ left: "30px", top: "45%" }}
                        >
                          <img src={LockIcon} alt="user-icon" />
                        </div>
                      </div>
                    </div>
                  </form>
                  {!passwordValid ? (
                    <div className="error-valid-login mt-2">
                      パスワードを入力してください
                    </div>
                  ) : (
                    <></>
                  )}
                  {errorCode === 401 || errorCode === 422 ? (
                    <div className="error-valid-login mt-2">{errorMsg}</div>
                  ) : (
                    <></>
                  )}
                </div>

                {/* <Link to="/" className="link"> */}
                <div
                  onClick={(e) => UserLogin(e, email, password)}
                  style={{
                    cursor: "pointer",
                    marginTop: "30.65px",
                    height: "51px",
                  }}
                  className="row button-text"
                >
                  <div
                    className="fs-30-px font-ethno-light d-flex justify-content-center px-5"
                    style={{ marginTop: "-0.5%" }}
                  >
                    LOGIN
                  </div>
                </div>
                {/* </Link> */}
              </div>
            </div>
            <div className="col d-flex">
              <div style={{ marginLeft: "15%", marginTop: "10%" }}>
                <div className="row">
                  {/* <Mask
                    width="300"
                    height="250"
                    id="test-mask"
                    className="d-flex justify-content-center"
                    crop
                  /> */}
                  <LoginMask
                    width="300"
                    height="280"
                    id="test-mask"
                    className="d-flex justify-content-center"
                  />
                </div>
                <div className="row" style={{ marginTop: "250px" }}>
                  <h5 className="text-primary fs-35-px font-ethno-light d-flex justify-content-center">
                    PERSONA
                  </h5>
                  <h5 className="text-primary fs-35-px font-ethno-light d-flex justify-content-center">
                    REALITY
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </LoginBox>
        {/*<ErrorModal isOpen={errorModal} onAction={() => setErrorModal(false)}>
          {/*errorModal}
                </ErrorModal>*/}
      </div>
    </>
  );
};

export default Login;
