import React from "react";
import AppWrapper from "../layouts/AppWrapper";
import ContentWrapper from "../layouts/ContentWrapper";
import { TextInput } from "../components/Form";

/** Content Body */
const Group = () => {
  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        // title={<Title />
        className="content-wider-title"
        title={<div className="mt-1">アップデートディテール</div>}
        topSpace={<TopSpace />}
        smallMarginRight
      >
        <div className="text-white overflow-auto h-70-vh hide-scroll ms-2">
          <div className="mt-3">
            <div className="d-flex gap-4">
              <div className="fs-20-px font-notosans-jp mt-1">タイプ名</div>
              <div className="ms-3">
                <TextInput
                  borderColor="#7A7373"
                  className="h-40-px w-230-px"
                  required
                  textboxClass="opacity-50"
                  inputClass="font-europa-regular fs-20-px ms-1"
                  value="Raiden Shogun"
                />
              </div>
            </div>
          </div>
          <div className="fs-20-px" style={{ marginTop: "35px" }}>
            カスタムパラメーター
          </div>
          <div className="mt-4 row gap-3">
            {[...Array(6)].map((e, i) => (
              <div className="col">
                <div className="fs-20-px">Parameter1</div>
                <div className="d-flex gap-1" style={{ marginTop: "6.5px" }}>
                  <div className="fs-20-px ">1.00</div>
                  <div
                    className="arrow-icon w-15-px "
                    style={{ marginTop: "12px", marginLeft: "5px" }}
                  />
                  <div className="w-60-px">
                    {i === 1 ? (
                      <TextInput
                        borderColor="#F634F2"
                        className="h-30-px w-60-px text-input-lesser-radius text-input-bigger-border"
                        required
                        textboxClass="w-60-px"
                      />
                    ) : (
                      <TextInput
                        borderColor="#7A7373"
                        className="h-30-px text-input-lesser-radius"
                        required
                        textboxClass="w-60-px opacity-50"
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div style={{ marginTop: "3.8%" }}>
            <div className="d-flex">
              <div className="fs-20-px">称号</div>
            </div>
            <div className="d-flex" style={{ marginTop: "13px" }}>
              <div className="w-70">
                <div className="mt-2 d-flex flex-column gap-4 h-150-px overflow-auto hide-scroll">
                  {[...Array(2)].map((e, i) => (
                    <div className="d-flex gap-4">
                      <TextInput
                        borderColor="#7A7373"
                        className="h-40-px lesser-opacity"
                        required
                        width="235.46px"
                        textboxClass="opacity-50"
                      />
                      {i === 1 ? (
                        <TextInput
                          borderColor="#F634F2"
                          className="ms-2 h-40-px text-input-bigger-border"
                          required
                          width="235.46px"
                        />
                      ) : (
                        <TextInput
                          borderColor="#7A7373"
                          className="ms-2 h-40-px lesser-opacity"
                          required
                          width="235.46px"
                          textboxClass="opacity-50"
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="w-30"
                style={{ marginRight: "8%", marginTop: "1.5%" }}
              >
                <div className="fs-20-px text-center">総合値</div>
                <div className="mt-4 d-flex gap-4 justify-content-center">
                  <div
                    className="grey-minus-icon"
                    style={{ marginTop: "5%" }}
                  ></div>
                  <div>
                    <TextInput
                      borderColor="#F634F2"
                      value="100"
                      className="h-30-px text-input-lesser-radius"
                      inputClass="text-center"
                    />
                  </div>
                  <div
                    className="pink-plus-icon"
                    style={{ marginTop: "2%" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </AppWrapper>
  );
};

/** Title */
// const Title = () => {
//   return <div className="d-flex">{/* Title Content */}</div>;
// };

/** Top Space */
const TopSpace = () => {
  return <></>;
};

export default Group;
