import { React, useEffect, useState } from "react";
import MessageBox from "../layouts/MessageBox";
import Button from "./Button";
import ErrorImg from "../assets/images/error-img.png";
import { ProgressBar } from "../components/ProgressBar";

export const Modal = (props) => {
  const [modal, setModal] = useState(false);
  const { children, isOpen, width, height, onClick, isChooseGroup, boxClass } =
    props;
  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);
  return (
    <>
      {modal && (
        <div className="cust-modal text-white" onClick={onClick}>
          <div className="overlay"></div>
          <div
            className="modal-content"
            style={{ width: width, height: height }}
          >
            <MessageBox
              boxClass={boxClass}
              isChooseGroup={isChooseGroup}
              className="w-100 h-100"
            >
              {children}
            </MessageBox>
          </div>
        </div>
      )}
    </>
  );
};

export const ConfirmModal = (props) => {
  const [modal, setModal] = useState(false);
  const { onConfirm, onCancel, children, isOpen } = props;
  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);
  return (
    <>
      {modal && (
        <div className="cust-modal text-white">
          <div className="overlay"></div>
          <div
            className="modal-content"
            style={{ width: "574px", height: "307.27px" }}
          >
            <MessageBox className="w-100 h-100">
              <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <div className="mt-2">
                  <div className="text-center fs-20-px font-notosans-jp">
                    {children}
                  </div>
                  <div className="d-flex justify-content-center gap-5 mt-90-px">
                    <Button
                      onClick={onCancel}
                      text="NO"
                      className="w-175-px h-40-px"
                      buttonClass="button-dialog"
                    ></Button>
                    <Button
                      onClick={onConfirm}
                      text="YES"
                      className="w-175-px h-40-px"
                      buttonClass="button-dialog"
                    ></Button>
                  </div>
                </div>
              </div>
            </MessageBox>
          </div>
        </div>
      )}
    </>
  );
};

export const InfoModal = (props) => {
  const [modal, setModal] = useState(false);
  const { onAction, children, isOpen, actionText = "OK" } = props;
  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);
  return (
    <>
      {modal && (
        <div className="cust-modal text-white">
          <div className="overlay"></div>
          <div
            className="modal-content"
            style={{ width: "574px", height: "307.27px" }}
          >
            <MessageBox className="w-100 h-100">
              <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <div className="mt-3">
                  <div className="text-center fs-20-px font-notosans-jp">
                    {children}
                  </div>
                  <div
                    className="d-flex justify-content-center gap-5"
                    style={{ marginTop: "87px" }}
                  >
                    <Button
                      onClick={onAction}
                      text={actionText}
                      className="w-175-px h-40-px"
                      buttonClass="button-dialog"
                    ></Button>
                  </div>
                </div>
              </div>
            </MessageBox>
          </div>
        </div>
      )}
    </>
  );
};

export const ErrorModal = (props) => {
  const [modal, setModal] = useState(false);
  const { onAction, children, isOpen, actionText = "OK" } = props;
  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);
  return (
    <>
      {modal && (
        <div className="cust-modal text-white">
          {/* <div className="overlay"></div> */}
          <div
            className="modal-content"
            style={{ width: "574px", height: "307.27px" }}
          >
            <MessageBox className="w-100 h-100">
              <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <div>
                  <div className="d-flex justify-content-center">
                    <img src={ErrorImg} alt="Error" />
                  </div>
                  <div className="text-center fs-20-px mt-4 font-notosans-jp">
                    {children}
                  </div>
                  <div className="d-flex justify-content-center gap-5 mt-50-px">
                    <Button
                      onClick={onAction}
                      // text={
                      //   <div className="fs-15-px font-ethno-light">
                      //     {actionText}
                      //   </div>
                      // }
                      text={actionText}
                      className="w-175-px h-40-px"
                      buttonClass="button-dialog"
                    ></Button>
                  </div>
                </div>
              </div>
            </MessageBox>
          </div>
        </div>
      )}
    </>
  );
};

export const LoadingModal = (props) => {
  const [modal, setModal] = useState(false);
  const {
    onAction,
    isOpen,
    actionText = "OK",
    progress = "0",
    delay = "3",
  } = props;
  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);
  return (
    <>
      {modal && (
        <div className="cust-modal text-white">
          <div className="overlay"></div>
          <div
            className="modal-content"
            style={{ width: "574px", height: "307.27px" }}
          >
            <MessageBox className="w-100 h-100">
              <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <div>
                  <div class="mt-3 mb-3 title-text">
                    <h5 class="fs-20-px px-4 text-white d-flex justify-content-center font-notosans-jp position-relative h-20-px">
                      {/* {children} */}
                      {/* <div className="loading-text position-absolute"> */}
                      <div
                        className="position-absolute"
                        style={{
                          transition: "opacity 2s",
                          opacity: (100 - progress) / 100,
                        }}
                      >
                        ローディング. . . .
                      </div>
                      {/* <div className="loading-complete-text position-absolute"> */}
                      <div
                        className="position-absolute"
                        style={{
                          transition: "opacity 2s",
                          opacity: progress / 100,
                        }}
                      >
                        完了
                      </div>
                    </h5>
                  </div>
                  <div class="mb-3 d-flex justify-content-center">
                    <ProgressBar
                      progress={progress}
                      delay={delay}
                      width="370px"
                      height="17.4px"
                    />
                  </div>
                  <div
                    //class name remove => opacity-button
                    className="d-flex justify-content-center gap-5 mt-60-px"
                    style={{
                      transition: "opacity 2s",
                      opacity: progress / 100,
                    }}
                  >
                    <Button
                      onClick={
                        progress === 100
                          ? onAction
                          : () => {
                              return;
                            }
                      }
                      // text={
                      //   <div className="fs-15-px font-ethno-light">
                      //     {actionText}
                      //   </div>
                      // }
                      text={actionText}
                      className="w-175-px h-40-px"
                      buttonClass="button-dialog"
                    ></Button>
                  </div>
                </div>
              </div>
            </MessageBox>
          </div>
        </div>
      )}
    </>
  );
};

export const UnReleaseLoadingModal = (props) => {
  const [modal, setModal] = useState(false);
  const { onAction, isOpen, actionText = "OK" } = props;
  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);
  return (
    <>
      {modal && (
        <div className="cust-modal text-white">
          <div
            className="modal-content"
            style={{ width: "574px", height: "307.27px" }}
          >
            <MessageBox className="w-100 h-100">
              <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <div>
                  <div class="mt-2 mb-3 title-text">
                    <h5 class="fs-20-px px-4 text-white d-flex justify-content-center position-relative h-20-px">
                      {/* {children} */}
                      <div className="loading-text position-absolute">
                        発行中. . . . .
                      </div>
                      <div className="loading-complete-text position-absolute">
                        完了
                      </div>
                    </h5>
                  </div>
                  <div class="mb-3 d-flex justify-content-center">
                    <ProgressBar width="370px" height="17.4px" />
                  </div>
                  <div className="opacity-button d-flex justify-content-center gap-5 mt-60-px">
                    <Button
                      onClick={onAction}
                      text={actionText}
                      className="w-175-px h-40-px"
                      buttonClass="button-dialog"
                    ></Button>
                  </div>
                </div>
              </div>
            </MessageBox>
          </div>
        </div>
      )}
    </>
  );
};
