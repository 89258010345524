import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./views/Home";
import Login from "./views/Login";
import Test from "./views/Test";
import ErrorMsg from "./views/ErrorMsg";
import Group from "./views/Group";
import Type from "./views/Type";
import Box from "./views/Box";
import GuidanceMsg from "./views/GuidanceMsg";
import ChooseGroup from "./views/ChooseGroup";
import Unrelease from "./views/Unrelease";
import Management from "./views/Management";
import UpdateHistory from "./views/UpdateHistory";
import Publish from "./views/Publish";
import SeedSetting from "./views/SeedSetting";
import ErrorMsgS from "./views/ErrorMsgS";
import ErrorMsgServer from "./views/ErrorMsgServer";
import DeleteGp from "./views/DeleteGp";
import DeleteGpSucc from "./views/DeleteGpSucc";
import DeletePara from "./views/DeletePara";
import DeleteParaSucc from "./views/DeletePareSucc";
import DeleteParaSuccOne from "./views/DeleteParaSuccOne";
import GroupSetting from "./views/GroupSetting";
import GroupCreate from "./views/GroupCreate";
import TypeSetting from "./views/TypeSetting";
import ManagementEdit from "./views/ManagementEdit";
import UpdateHistoryDetail from "./views/UpdateHistoryDetail";
import LoadingBox from "./views/LoadingBox";
import TypeCreate from "./views/TypeCreate";

const RouteList = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="/test" element={<Test />} />
      <Route path="/error-msg" element={<ErrorMsg />} />
      <Route path="/loading-msg" element={<LoadingBox />} />
      <Route path="/error-msg-s" element={<ErrorMsgS />} />
      <Route path="/error-msg-server" element={<ErrorMsgServer />} />
      <Route path="/box" element={<Box />} />
      <Route path="/delete-gp" element={<DeleteGp />} />
      <Route path="/delete-para" element={<DeletePara />} />
      <Route path="/delete-para-succ" element={<DeleteParaSucc />} />
      <Route path="/delete-para-succ-one" element={<DeleteParaSuccOne />} />
      <Route path="/delete-gp-succ" element={<DeleteGpSucc />} />
      <Route path="/guidance-msg" element={<GuidanceMsg />} />
      <Route path="/choose-group" element={<ChooseGroup />} />

      {/* Groups */}
      <Route path="/groups" element={<Group />} />
      <Route path="/groups/:id" element={<GroupSetting />} />
      <Route path="/groups/create" element={<GroupCreate />} />
      {/*End Groups */}

      {/* Types */}
      <Route path="/types" element={<Type />} />
      <Route path="/types/:id" element={<TypeSetting />} />
      <Route path="/types/create" element={<TypeCreate />} />
      {/* End Types */}

      <Route path="/publish" element={<Publish />} />
      <Route path="/unreleases" element={<Unrelease />} />

      {/* Management */}
      <Route path="/management" element={<Management />} />
      <Route path="/management/:id" element={<ManagementEdit />} />
      {/* End Management */}

      <Route path="/seed-settings" element={<SeedSetting />} />

      {/* Update History */}
      <Route path="/update-histories" element={<UpdateHistory />} />
      <Route path="/update-histories/:id" element={<UpdateHistoryDetail />} />
      {/* End History */}
    </Routes>
  );
};

export default RouteList;
