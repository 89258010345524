import { React } from "react";

export const TextInput = (props) => {
  const {
    type = "text",
    value,
    defaultValue,
    onChange,
    onClick,
    placeholder,
    width,
    height,
    className,
    inputClass,
    required,
    isError,
    errorMessage,
    errorClass,
    borderColor,
    textboxClass,
    inactive,
    readonly,
    pattern,
    onKeyDown,
  } = props;

  return (
    <>
      <div
        className={`text-input ${className}`}
        style={{ width: width, height: height }}
      >
        <div
          className={`text-box ${
            errorMessage || isError ? "error-border" : ""
          } ${
            inactive && !isError && !errorMessage ? "inactive" : ""
          } ${textboxClass} `}
          style={
            borderColor
              ? {
                  backgroundColor: borderColor,
                }
              : {}
          }
        ></div>
        <input
          type={type}
          className={`input hide-placeholder ${
            inputClass ? inputClass : "fs-20-px"
          }`} //you can set font size from inputClass
          value={value}
          defaultValue={defaultValue}
          onClick={onClick}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          readonly={readonly}
          pattern={pattern}
          onKeyDown={onKeyDown}
        />
        {errorMessage && (
            <div className={` ${errorClass ? errorClass : "error"}`}>
            {errorMessage}{" "}
          </div>
        )}
      </div>
    </>
  );
};

export const LoginInput = (props) => {
  const {
    type = "text",
    value,
    onChange,
    onBlur,
    placeholder,
    width,
    height,
    className,
    inputClass,
    required,
    id,
  } = props;

  return (
    <div
      className={`login-input ${className}`}
      style={{ width: width, height: height }}
    >
      <div className="left-wrapper"></div>
      <div className="text-box"></div>
      <input
        type={type}
        className={`input ${
          type === "password" ? "fs-30-px pb-1" : "fs-20-px"
        } ${inputClass}`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        required={required}
        id={id}
        autoComplete="off"
        style={{ letterSpacing: type === "password" ? 4 : "" }}
      />
      <div className="right-wrapper"></div>
    </div>
  );
};

export const SearchInput = (props) => {
  const {
    value,
    onChange,
    placeholder,
    width,
    height,
    className,
    required,
    onKeyDown,
  } = props;

  return (
    <div
      onKeyDown={onKeyDown}
      className={`search-input ${className}`}
      style={{ width: width, height: height }}
    >
      <div className="search-box">
        <input
          type="text"
          className="input smaller-placeholder hide-placeholder"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
        />
      </div>
    </div>
  );
};
