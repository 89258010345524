// eslint-disable-next-line import/no-anonymous-default-export
export default {
    dashboard: {
        group: 'Group',
        unpublished: 'Unpublished',
    },   
    seedSetting: {
        title: 'Seed Setting',
        labelconvert: 'When converting from persona to arcana, seed values are calculated from random numbers according to the following formula.',
        labelrandom: 'Number of random number table elements',
        labelmagnification: 'Magnification',
        labelprobability: 'Probability',
        labelformat: 'Seed value = multiplier drawn from random number table x total value of tokens',
        labelexpectedmultiple: 'Expected Multiple',
        labelformula: '(Expected magnification = Σ[k=1, n]magnification x probability)',
        labeltotal: `The total probability must become <span class="text-primary fs-25-px">100%</span>`,
        labeltotalprobability: `Total Probability`,
        labellackprobability: `Lack of Probability`,
    },
    group: {
        title: 'Group List',
        placeholdersearch: 'Search by group name or group number',
        groupname: 'Group Name',
        groupnumber: 'Group Number',
        numberofpersonas: 'Number of Personas',
        createddate: 'Created date',
        action: 'Action',
    },
    type: {
        title: 'Type List',
        placeholdersearch: 'Search by type name or type number',
        typename: 'Type Name',
        typenumber: 'Type Number',
        groupname: 'Group Name',
        createddate: 'Created date',
        numberofpersonas: 'Number of Personas',
        action: 'Action',
    }, 
    management: {
        title: 'Persona Management',
        placeholdersearch: 'Search by group name or group number',
        typename: 'Type Name',
        typenumber: 'Type Number',
        groupname: 'Group Name',
        createddate: 'Created date',
        numberofpersonas: 'Number of Personas',
        action: 'Action',
    }, 
    button: {
        create: 'Create',
    }, 
    menu: {
        group: 'Group',
    }, 
}
